<template>
	<div class="contactUsDiv">
		<div class="contactUs">
			<div class="cu_title">{{$t('aboutUs.contactUs.title3')}}</div>
			<div class="cu_content">
				<div class="cu_ct_div" v-for="(item,index) in $t('aboutUs.contactUs.list')" :key="index">
					<div class="cu_ct_div_name">{{item.name}}</div>
					<div class="cu_ct_div_line"></div>
					<div class="cu_ct_div_phone">
						<img class="cu_ct_div_ph_icon" :src="img2" />
						<div>{{$t('aboutUs.contactUs.text2')}}：{{item.phone}}</div>
					</div>
					<div class="cu_ct_div_phone">
						<img class="cu_ct_div_ph_icon" :src="img3" />
						<div>{{$t('aboutUs.contactUs.text3')}}：{{item.email}}</div>
					</div>

					<div class="cu_ct_div_address">
						<img class="cu_ct_div_add_icon" :src="img4" />
						<div>{{$t('aboutUs.contactUs.text4')}}：{{item.address}}</div>
					</div>
				</div>
			</div>

			<el-image style="width: 100%;" :src="img" :fit="'contain'"></el-image>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				img:process.env.VUE_APP_OSS_URL + '/aboutUs/contact.png',
				img2:process.env.VUE_APP_OSS_URL + '/aboutUs/phone.png',
				img3:process.env.VUE_APP_OSS_URL + '/aboutUs/email.png',
				img4:process.env.VUE_APP_OSS_URL + '/aboutUs/address.png',
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.contactUsDiv{
			
			.contactUs{
				padding: 80px 200px;
				
				.cu_title{
					font-size: 28px;
					color: #333;
					text-align: center;
					font-weight: bold;
					padding-bottom: 40px;
				}
				
				.cu_content{
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					
					.cu_ct_div{
						width: 50%;
						padding: 20px 20px 20px 0;
						
						.cu_ct_div_name{
							font-size: 24px;
							color: #333;
						}
						
						.cu_ct_div_line{
							width: 40px;
							height: 2px;
							background: #d1102d;
							margin-top: 20px;
						}
						
						.cu_ct_div_phone{
							display: flex;
							align-items: center;
							margin-top: 20px;
							
							.cu_ct_div_ph_icon{
								width: 30px;
								height: 30px;
								margin: 10px 10px 10px 0;
							}
						}
						
						.cu_ct_div_address{
							display: flex;
							align-items: center;
							margin-top: 20px;
							
							.cu_ct_div_add_icon{
								width: 30px;
								height: 30px;
								margin: 10px 10px 10px 0;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.contactUsDiv{
			
			.contactUs{
				padding: 80px 200px;
				
				.cu_title{
					font-size: 28px;
					color: #333;
					text-align: center;
					font-weight: bold;
					padding-bottom: 40px;
				}
				
				.cu_content{
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					
					.cu_ct_div{
						width: 50%;
						padding: 20px 20px 20px 0;
						
						.cu_ct_div_name{
							font-size: 24px;
							color: #333;
						}
						
						.cu_ct_div_line{
							width: 40px;
							height: 2px;
							background: #d1102d;
							margin-top: 20px;
						}
						
						.cu_ct_div_phone{
							display: flex;
							align-items: center;
							margin-top: 20px;
							
							.cu_ct_div_ph_icon{
								width: 30px;
								height: 30px;
								margin: 10px 10px 10px 0;
							}
						}
						
						.cu_ct_div_address{
							display: flex;
							align-items: center;
							margin-top: 20px;
							
							.cu_ct_div_add_icon{
								width: 30px;
								height: 30px;
								margin: 10px 10px 10px 0;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.contactUsDiv{
			
			.contactUs{
				padding: 80px 140px;
				
				.cu_title{
					font-size: 28px;
					color: #333;
					text-align: center;
					font-weight: bold;
					padding-bottom: 40px;
				}
				
				.cu_content{
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					
					.cu_ct_div{
						width: 50%;
						padding: 20px 20px 20px 0;
						
						.cu_ct_div_name{
							font-size: 24px;
							color: #333;
						}
						
						.cu_ct_div_line{
							width: 40px;
							height: 2px;
							background: #d1102d;
							margin-top: 20px;
						}
						
						.cu_ct_div_phone{
							display: flex;
							align-items: center;
							margin-top: 20px;
							
							.cu_ct_div_ph_icon{
								width: 30px;
								height: 30px;
								margin: 10px 10px 10px 0;
							}
						}
						
						.cu_ct_div_address{
							display: flex;
							align-items: center;
							margin-top: 20px;
							
							.cu_ct_div_add_icon{
								width: 30px;
								height: 30px;
								margin: 10px 10px 10px 0;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.contactUsDiv{
			
			.contactUs{
				padding: 80px 30px;
				
				.cu_title{
					font-size: 28px;
					color: #333;
					text-align: center;
					font-weight: bold;
					padding-bottom: 40px;
				}
				
				.cu_content{
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					
					.cu_ct_div{
						width: 50%;
						padding: 20px 20px 20px 0;
						
						.cu_ct_div_name{
							font-size: 24px;
							color: #333;
						}
						
						.cu_ct_div_line{
							width: 40px;
							height: 2px;
							background: #d1102d;
							margin-top: 20px;
						}
						
						.cu_ct_div_phone{
							display: flex;
							align-items: center;
							margin-top: 20px;
							
							.cu_ct_div_ph_icon{
								width: 30px;
								height: 30px;
								margin: 10px 10px 10px 0;
							}
						}
						
						.cu_ct_div_address{
							display: flex;
							align-items: center;
							margin-top: 20px;
							
							.cu_ct_div_add_icon{
								width: 30px;
								height: 30px;
								margin: 10px 10px 10px 0;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.contactUsDiv{
			
			.contactUs{
				padding: 80px 30px;
				
				.cu_title{
					font-size: 21px;
					color: #333;
					text-align: center;
					font-weight: bold;
					padding-bottom: 40px;
				}
				
				.cu_content{
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					
					.cu_ct_div{
						width: 50%;
						padding: 20px 20px 20px 0;
						
						.cu_ct_div_name{
							font-size: 16px;
							color: #333;
						}
						
						.cu_ct_div_line{
							width: 40px;
							height: 2px;
							background: #d1102d;
							margin-top: 20px;
						}
						
						.cu_ct_div_phone{
							display: flex;
							align-items: center;
							margin-top: 20px;
							
							.cu_ct_div_ph_icon{
								width: 30px;
								height: 30px;
								margin: 10px 10px 10px 0;
							}
						}
						
						.cu_ct_div_address{
							display: flex;
							align-items: center;
							margin-top: 20px;
							
							.cu_ct_div_add_icon{
								width: 30px;
								height: 30px;
								margin: 10px 10px 10px 0;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.contactUsDiv{
			
			.contactUs{
				padding: 80px 30px;
				
				.cu_title{
					font-size: 21px;
					color: #333;
					text-align: center;
					font-weight: bold;
					padding-bottom: 40px;
				}
				
				.cu_content{
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					
					.cu_ct_div{
						width: 100%;
						padding: 20px 20px 20px 0;
						
						.cu_ct_div_name{
							font-size: 16px;
							color: #333;
						}
						
						.cu_ct_div_line{
							width: 40px;
							height: 2px;
							background: #d1102d;
							margin-top: 20px;
						}
						
						.cu_ct_div_phone{
							display: flex;
							align-items: center;
							margin-top: 20px;
							
							.cu_ct_div_ph_icon{
								width: 30px;
								height: 30px;
								margin: 10px 10px 10px 0;
							}
						}
						
						.cu_ct_div_address{
							display: flex;
							align-items: center;
							margin-top: 20px;
							
							.cu_ct_div_add_icon{
								width: 30px;
								height: 30px;
								margin: 10px 10px 10px 0;
							}
						}
					}
				}
			}
		}
	}
	
</style>
